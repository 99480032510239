import React from 'react';
import { SaleItemUI, SaleItemUIProps } from '../sales/SaleItem';
import type { RawCompletedSaleSearchItem } from './useRawCompletedSalesSearch';

export type SalesHistoryItemProps = Omit<
  SaleItemUIProps,
  'finalPrice' | 'imageUrl' | 'listingType' | 'platformId' | 'saleDate' | 'saleTitle' | 'saleUrl'
> & {
  item: RawCompletedSaleSearchItem;
};
export type SalesHistoryItemSize = 'md' | 'lg';

export function SalesHistoryItem({ item, ...rest }: SalesHistoryItemProps) {
  return (
    <SaleItemUI
      {...rest}
      finalPrice={item.finalPrice}
      imageUrl={item.imageUrls[0] ?? null}
      listingType={item.listingType ?? 'FixedPrice'}
      platformId={item.platformId}
      saleDate={item.saleDate}
      saleTitle={item.displayTitle}
      saleUrl={item.saleUrl}
    />
  );
}
